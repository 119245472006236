import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import Confetti from 'react-confetti';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getDistance } from 'geolib';
import { motion } from "framer-motion";
import Loading from '../utils/Loading';
import {Column, Row} from "simple-flexbox";
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import {validateEmail} from '../utils/HelpfulFunction';
import ProgressBar from "@ramonak/react-progress-bar";

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: sessionStorage.getItem('userEmail') || false,
            gameActive: false,
            seenVideo: false,
            tenantRules: {},
            currentPick: {},
            questions: [],
            answers: [],
            questionIndex: 0,
            hintLocation: 0,
            selectedItem: 0,
            status: 0,
            timeToSendEmails: null,
            width: 0,
            height: 0,
            highlightedPick: false,
            questionAnswered: false,
            formFilledOut: false,
            currentResponseArray: [],
            alreadyUpdate: "",
            currentGameId: null,
            locationChecking: true,
            loading: true,
            startConfetti: false,
            downloadingImage: false,
            downloadedFile: null,
            tierMessaging: [],
            startAnimating: true,
            blurPixels: 25,
            barProgress: 0
        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    componentDidMount() {
        if(!navigator.cookieEnabled){
            alert("You do not have cookies enabled! Please change the settings of your browser");
            return;
        }
        this.handleGameMethods();

        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.scrollTo(0,0);
    }

    componentWillUnmount() {
        base.removeBinding(this.currentResponsesRef);
        base.removeBinding(this.questionsRef);
        base.removeBinding(this.gameActiveRef);
        base.removeBinding(this.currentGameKeyRef);
        base.removeBinding(this.statusRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.tierMessagingRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevState.status && !this.state.status) || (prevState.gameActive && !this.state.gameActive)){
            this.resetGame();
        }
    }

    resetGame(){
        this.removeReactAlert();
        this.setState({
            highlightedPick: false,
            startConfetti:false,
            winnerModal: false,
            gameEnded: false,
            blurPixels: 25,
            barProgress: 0,
            questionIndex: 0,
            hintLocation: 0,
            alreadyStartedProcess: false,
            secondTry: false
        })
        if(this.state.totalDeblurIntervalId){
            clearInterval(this.state.totalDeblurIntervalId)
        }
        if(this.state.hintCyclingIntervalId){
            clearInterval(this.state.hintCyclingIntervalId)
        }
        this.setState({
            hintCyclingIntervalId: null,
            totalDeblurIntervalId: null
        })
    }

    handleGameMethods(){
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
            context: this,
            then(key){
                this.logUserActivity(key)
                this.resetGame();
                this.setState({
                    currentGameId:key,
                })
            }
        });
        this.gameActiveRef = base.bindToState('currentGame/active', {
            context: this,
            state: 'gameActive'
        })
        this.questionsRef = base.bindToState('currentGame/questions', {
            context: this,
            state: 'questions',
            asArray: true
        })
        this.tierMessagingRef = base.bindToState('currentGame/tierMessaging', {
            context: this,
            state: 'tierMessaging',
        });
        this.currentResponsesRef = base.listenTo('userAnswers/'+base64EncodedEmail, {
            context: this,
            state: 'currentResponseArray',
            asArray: true,
            then(arrayOfAnswers){
                if(this.props.variables && this.props.variables.collectDistance && !this.state.currentPick.pickid && this.props.variables.latitude && this.props.variables.acceptableDistance){
                    this.checkUsersLocation();
                } else {
                    let numberOfAnswers = arrayOfAnswers.length;
                    if(numberOfAnswers > 1){
                        numberOfAnswers = numberOfAnswers - 1;
                    }
                    this.setState({
                        questionIndex: numberOfAnswers,
                        currentResponseArray: arrayOfAnswers,
                        locationChecking: false
                    }, () => {
                        let questionsAnswered = false;
                        if(numberOfAnswers === this.state.questions.length){
                            questionsAnswered = true;
                            this.getPrize();
                        } else {
                            this.setState({
                                loading: false,
                            })
                        }
                        this.setState({
                            questionsAnswered: questionsAnswered
                        })
                    })
                }
            }
        })

        this.statusRef = base.listenTo('currentGame/status', {
            context: this,
            state: 'status',
            then(status){
                this.setState({
                    status: status
                }, () => {
                    if(status === 0 && this.state.gameActive){
                        this.checkIfGameIsReadyToStart();
                    } else if(status === 1 && this.state.currentResponseArray.length === 0) {
                        this.getPrize();
                    }
                });
            }
        })
    }

    getPrize() {
        const userResponse = {};
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(userEmail && userEmail.indexOf('@') === -1){
            base64EncodedEmail = userEmail
        }
        const vm = this;
        userResponse['userInfo'] = base64EncodedEmail;
        userResponse['tenantVariables'] = this.props.variables || {};
        if(process.env.REACT_APP_FIREBASE_PROJECT_ID === "chicagobearsreveal"){
            userResponse['userNewEmailBears'] = true;
        }
        if(this.state.currentResponseArray.length === 0){
            vm.showGameOverMessage();
            return;
        }
        fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/immediatePrizeCheck`, {
        //     fetch(`http://localhost:5001/revealplayerdev/us-central1/immediatePrizeCheck`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userResponse),
        }).then(res => {
            vm.setState({
                loading: false
            })
            Promise.resolve(res.json()).then(function (value) {
                if(value.error){
                    confirmAlert({
                        title: "Oh No!",
                        variables: vm.props.variables,
                        message: "Something went wrong, please try again!",
                        buttons: [
                            {
                                label: "Ok",
                                onClick: () => {
                                    vm.getPrize();
                                }
                            }
                        ]
                    });
                } else {
                    if(vm.state.currentResponseArray.length === 0) {
                        vm.showGameOverMessage();
                    } else if(value.prizeWon) {
                        vm.showGameOverMessage(true)
                    } else {
                        vm.showGameOverMessage(false)
                    }
                }
            })
        })
        .catch((error) => {
            if(!this.state.secondTry){
                this.setState({
                    secondTry: true
                })
                this.getPrize()
            }
            console.log("FAILED TO GEt PRIZE")
            console.log(error)
        });
    }

    showGameOverMessage(gotWinningMessage){
        const currentAnswerArray = this.state.currentResponseArray;
        const tenantVariables = this.props.variables || {};
        const questions = this.state.questions;
        const answers = [];
        for(const y in questions){
            const questionAnswers = questions[y].answers;
            for(const answerIndex in questionAnswers){
                if(currentAnswerArray.indexOf(questionAnswers[answerIndex].id) !== -1){
                    questionAnswers[answerIndex].shown =  questions[y].shown;
                    answers.push(questionAnswers[answerIndex])
                }
            }
        }
        let count = 0;
        for(const answerIndex in answers){
            const answer = answers[answerIndex];
            if(answer.correct){
                count+=1
            }
        }
        const tierMessages = this.state.tierMessaging[count];
        let headerHeader = tenantVariables.gameOverHeader || "Game Already Ended!";
        let headerMessage = tenantVariables.gameOverBody || "The game already happened, come back next time to play.";
        let messageImage = "";
        if(gotWinningMessage === true){
            headerHeader = tierMessages.winningHeader;
            headerMessage = tierMessages.winningMessage;
            messageImage = tenantVariables.winningMessageImage;
            if(count > 0){
                this.setState({
                    startConfetti: true
                })
            }
        } else if(gotWinningMessage === false){
            headerHeader = tierMessages.winningMissedHeader;
            headerMessage = tierMessages.winningMissedMessage;
        }
        this.setState({
           gameEnded: true
        })
        confirmAlert({
            variables: this.props.variables,
            messageImage:messageImage,
            title: headerHeader,
            message: headerMessage,
            buttons: []
        })
    }

    removeReactAlert(){
        document.body.classList.remove('react-confirm-alert-body-element');
        const target = document.getElementById('react-confirm-alert');
        if(target){
            unmountComponentAtNode(target)
            target.parentNode.removeChild(target);
        }
        const svg = document.getElementById('react-confirm-alert-firm-svg');
        if(svg){
            svg.parentNode.removeChild(svg)
            document.body.children[0].classList.remove('react-confirm-alert-blur');
        }
    }

    checkIfGameIsReadyToStart(){
        if(this.state.status === 0 && this.state.blurImageLoaded && this.state.sponsorImageLoaded && this.state.gameActive && !this.state.alreadyStartedProcess && !this.state.gameEnded){
            this.setState({
                alreadyStartedProcess: true
            }, () => {
                this.startGameProcess();
            })
        }
    }

    startGameProcess(){
        const { variables={} } = this.props;
        const { questions=[], questionIndex=0 } = this.state;
        const deblurTime = variables.timeToDeblur || 30;
        if(questions.length > 0){
            this.startTimers(questions, deblurTime, questionIndex);
        }
    }

    startTimers(questions, deblurTime, questionIndex){
        const currentQuestion = questions[questionIndex] || {};
        const hints = currentQuestion.hints || [];
        const numberOfHints = hints.length;
        const amountToDeblurPerSecond = 25/deblurTime;
        let amountAmountOfPixelsLeft = 25;
        const vm = this;
        let hintCyclingIntervalId = null;
        let hintLocation = this.state.hintLocation || 0;
        if(hints.length > 1){
            hintCyclingIntervalId = setInterval(function(){
                hintLocation = hintLocation + 1;
                if(hintLocation >= numberOfHints){
                    clearInterval(vm.state.hintCyclingIntervalId);
                    vm.setState({
                        hintCyclingIntervalId:null
                    })
                } else {
                    vm.setState({
                        hintLocation: hintLocation,
                        startAnimating: false
                    }, () => {
                        vm.setState({
                            startAnimating: true
                        })
                    })
                }
            }, deblurTime/numberOfHints * 1000);
        }
        let totalDeblurIntervalId = setInterval(function(){
            if(amountAmountOfPixelsLeft <= 2){
                clearInterval(vm.state.totalDeblurIntervalId);
                vm.setState({
                    totalDeblurIntervalId:null
                })
            }
            amountAmountOfPixelsLeft = amountAmountOfPixelsLeft - amountToDeblurPerSecond;
            let changePicturePixels = amountAmountOfPixelsLeft;
            if(changePicturePixels < 2){
                changePicturePixels = 2;
            }
            let barProgress = 0;
            if(amountAmountOfPixelsLeft * 4 < 100){
                barProgress = 100 - amountAmountOfPixelsLeft * 4;
            }
            vm.setState({
                blurPixels: changePicturePixels,
                barProgress: barProgress
            });
        }, 1000);

        this.setState({
            totalDeblurIntervalId: totalDeblurIntervalId,
            hintCyclingIntervalId: hintCyclingIntervalId
        })
    }

    handleImageLoaded(imageName){
        this.setState({
            [imageName + "Loaded"]: true
        }, () => {
            this.checkIfGameIsReadyToStart()
        })
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    winnerToggle() {
        this.setState({
            winnerModal: !this.state.winnerModal,
            startConfetti: false,
            downloadedFile: null
        });
    }

    changeMilesToMeters(milesToConvert){
        return milesToConvert*1609.344;
    }

    getLocation(){
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by your browser. Please change browsers to play!");
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                        } else {
                            console.log(err.message);
                            reject("An unknown error occurred, check your internet connection and try again");
                        }
                    }
                );
            }
        })
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            startConfetti: false
        });
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules,
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport,
        });
    }

    getLocationPermission(){
        const locationErrorTitle = "Location Error";
        this.getLocation().then(distance_meters => {
            sessionStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                confirmAlert({
                    title: locationErrorTitle,
                    variables: this.props.variables,
                    message: "Too far from game area to participate!",
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({
                modal:false,
                loading:false
            });
            sessionStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const variables = this.props.variables;
        const locationPermissions = sessionStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    logout(){
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('verifiedAge');
        sessionStorage.removeItem('birthday');
        sessionStorage.removeItem('locationPermissions');
        this.setState({
            userEmail:false
        })
    }

    logUserActivity(gameId){
        const vm = this;
        if(gameId){
            let base64EncodedEmail = btoa(this.state.userEmail);
            if(!validateEmail(this.state.userEmail)){
                base64EncodedEmail = this.state.userEmail;
            }
            base.post('userGameHistory/'+base64EncodedEmail+`/${gameId}` , {
                data:gameId,
                then(err){
                    if(!err){
                        vm.setState({
                            alreadyUpdate: gameId,
                        })
                        console.log("user game logged!")
                    }
                }
            })
        }
    }

    onClickPick(pickClicked, position){
        const tenantVariables = this.props.variables || {};
        const alreadyHappenedHeader = tenantVariables.gameOverHeader || "Game Already Ended!";
        const alreadyHappenedMessage = tenantVariables.gameOverBody || "The game already happened, come back next time to play.";
        let numberOfResponses = this.state.currentResponseArray.length - 1;
        if(numberOfResponses >= this.state.questions.length){
          return
        }
        if(this.state.status === 1){
            confirmAlert({
              title: alreadyHappenedHeader,
              variables: this.props.variables,
              message: alreadyHappenedMessage,
              buttons: [
                {
                  label: 'OK',
                }
              ]
            });
            return;
        }
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        const postToUserAnswers = {};
        const vm = this;
        const questionId = this.state.questions[this.state.questionIndex].id;
        postToUserAnswers["/userAnswers/" + base64EncodedEmail + "/" + questionId] = pickClicked.id;
        postToUserAnswers["/userAnswers/" + base64EncodedEmail + '/timeStamp'] = new Date().getTime().toString();
        const gameHeaderPick = tenantVariables.selectCharacterGameHeader || "Select This Choice?";
        const gameBodyPick = tenantVariables.selectCharacterGameBody || "Once you choose you're locked in & can't switch.";
        confirmAlert({
            title: gameHeaderPick,
            variables: vm.props.variables,
            message: gameBodyPick,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        appDatabasePrimaryFunctions.ref().update(postToUserAnswers, function(error){
                            if(!error){
                                if(vm.state.hintCyclingIntervalId){
                                    clearInterval(vm.state.hintCyclingIntervalId);
                                }
                                if(vm.state.totalDeblurIntervalId){
                                    clearInterval(vm.state.totalDeblurIntervalId);
                                }
                            } else {
                                console.log(error);
                                confirmAlert({
                                    title: 'There was an error',
                                    variables: vm.props.variables,
                                    message: error.message,
                                    buttons: [
                                        {
                                            label: 'OK',
                                        }
                                    ]
                                })
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("dismiss")
                }
            ],
        })
    }

    renderHoldingScreen(){
        const tenantVariables = this.props.variables || {};
        const textAbovePlayingText = tenantVariables.textAbovePlayingText || "Next game starts during the";
        const textColor = tenantVariables.textColor || "#fff";
        return(
            <>
                <div className="hero-text-container">
                  <img src={tenantVariables.frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <p style={{display: tenantVariables.playingText ? '' : "none", color:textColor}}>{textAbovePlayingText} <br/><span className="emphasizedText" style={{backgroundColor:tenantVariables.primaryColor, color:tenantVariables.secondaryColor}}>{tenantVariables.playingText}</span></p>
            </>
        )
    }

    renderPlayingScreen(){
        const tenantVariables = this.props.variables || {};
        const questions = this.state.questions || [];
        const currentAnswerArray = this.state.currentResponseArray || [];
        let questionNumber = this.state.questionIndex || 0;
        let currentQuestion = {};
        let questionAnswers = [];
        let currentHint = "";
        if(questions.length > 0 && questionNumber <= questions.length){
            if(questionNumber === questions.length){
                questionNumber = questionNumber - 1
            }
            currentQuestion = questions[questionNumber] || {};
            if(currentQuestion && currentQuestion.answers && currentQuestion.answers.length > 0){
                questionAnswers = currentQuestion.answers;

            }
            if(currentQuestion && currentQuestion.hints && currentQuestion.hints.length > 0){
                currentHint = currentQuestion.hints[this.state.hintLocation].hintText;
            }
        }
      const whoWillWinText = currentQuestion.questionText || "";
      return(
            <>
                {this.state.gameEnded &&
                    <div className="user" style={{marginTop: 10}}>
                        <img src={tenantVariables.topLeftImage} alt="" style={{marginBottom: 0, maxWidth: "100%"}}/>
                    </div>
                }
                {!this.state.gameEnded &&
                    <>
                        <svg className='hideSvgSoThatItSupportsFirefox'>
                            <filter id='sharpBlur'>
                                <feGaussianBlur stdDeviation={this.state.blurPixels}/>
                                <feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 9 0'/>
                                <feComposite in2='SourceGraphic' operator='in'/>
                            </filter>
                        </svg>
                        <Row horizontal="center">
                            <Column style={{maxWidth: 250, width: "50%"}}>
                                <Row horizontal="center" vertical="center">
                                    <img className="fade-in-image" src={tenantVariables.sponsorImage} onLoad={()=>this.handleImageLoaded("sponsorImage")} alt="" style={{maxWidth: "100%", maxHeight:"100%",width:"auto",height:"auto", padding:"1%"}}/>
                                </Row>
                                {this.state.startAnimating && this.state.sponsorImageLoaded && this.state.blurImageLoaded &&
                                    <Row horizontal="center" vertical="center" style={{fontSize: "2.5vh", fontWeight: "bolder", textAlign: "center", height: "100%"}}>
                                        <motion.div initial={{ x: "-30vw", opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
                                            {currentHint}
                                        </motion.div>
                                    </Row>
                                }
                            </Column>
                            <Column horizontal="center" vertical="center" style={{padding:"1%", maxWidth: 250, width: "50%"}}>
                                <img id="imageToBlur" className='svgBlur mbs fade-in-image' onLoad={()=> this.handleImageLoaded("blurImage")} src={currentQuestion && currentQuestion.gifSelectionImage} alt="" style={{maxWidth: "100%", maxHeight:"100%",width:"auto",height:"auto"}}/>
                            </Column>
                        </Row>
                        {this.state.sponsorImageLoaded && this.state.blurImageLoaded &&
                            <motion.div initial={{ x: "-30vw", opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
                                <div style={{maxWidth:500, marginLeft: "auto", marginRight: "auto"}}>
                                    <ProgressBar bgColor={tenantVariables.primaryColor ? tenantVariables.primaryColor: "#000"} transitionTimingFunction={"linear"} borderRadius="0" isLabelVisible={false} completed={this.state.barProgress} />
                                </div>
                                <div className="top-text">
                                    <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.textColor}}>{whoWillWinText}</p>
                                </div>
                                <div style={{maxWidth:500, marginLeft: "auto", marginRight: "auto"}}>
                                    <Carousel interval={90000000} autoPlay={false} showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem}
                                              onChange={(e) => {
                                                  this.setState({
                                                      selectedItem: e,
                                                  });
                                              }}>
                                        {
                                            questionAnswers.map(function(item,i){
                                                if(currentAnswerArray.indexOf(item.id) !== -1 || item.id === this.state.highlightedPick){
                                                    return <div
                                                        key={i}
                                                        id={item.id}
                                                        className="grid-item"
                                                        style={{background: tenantVariables.highlightPickColor, boxShadow: "none", border: "none"}}>
                                                        <img src={item.answerImage} style={{maxWidth: 250, maxHeight: 250}} />
                                                    </div>
                                                } else {
                                                    return <div
                                                        key={i}
                                                        id={item.id}
                                                        className="grid-item"
                                                        onClick={()=>this.onClickPick(item, i)}
                                                        style={{background: 'transparent', boxShadow: "none", border: "none"}}>
                                                        <img src={item.answerImage} style={{maxWidth: 250, maxHeight: 250}} />
                                                    </div>
                                                }
                                            }, this)
                                        }
                                    </Carousel>
                                </div>
                                <style dangerouslySetInnerHTML={{
                                    __html: [
                                        '.carousel .control-next.control-arrow:before {',
                                        'border-left: 32px solid ' + tenantVariables.highlightPickColor,
                                        ';border-top: 16px solid transparent',
                                        ';border-bottom: 16px solid transparent',
                                        '}',
                                        '.carousel .control-prev.control-arrow:before {',
                                        'border-right: 32px solid ' + tenantVariables.highlightPickColor,
                                        ';border-top: 16px solid transparent',
                                        ';border-bottom: 16px solid transparent',
                                        '}',
                                        '.carousel .thumb.selected, .carousel .thumb:hover {',
                                        '  border: 3px solid ' + tenantVariables.highlightPickColor,
                                        '}'
                                    ].join('\n')
                                }}>
                                </style>
                            </motion.div>
                        }
                    </>
                }
            </>
        )
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const tenantRules = this.state.tenantRules || {};
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
        let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
        let rulesPopUpText = tenantRules.rulesPopUpText;
        let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
        const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
        const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
        const link = tenantRules.rulesAndRegsLink;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const isBearsApp = process.env.REACT_APP_FIREBASE_PROJECT_ID === "chicagobearsreveal";
        const rulesShowInApp = tenantRules.rulesShowInApp || isMlbApp || false;
        if(rulesShowInApp) {
            if(!rulesShowInAppPopUpText && rulesPopUpText){
                rulesShowInAppPopUpText = rulesPopUpText;
            }
            if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
                rulesShowInAppPopUpHeader = rulesPopUpHeader;
            }
        }
        if(!this.state.userEmail){
            let nextUrl = 'login'
            return <Redirect to={nextUrl} />
        } else if(!tenantVariables.doNotCollectEmail && !isMlbApp && !validateEmail(this.state.userEmail)){
            this.logout();
        } else if((tenantVariables.doNotCollectEmail || isMlbApp) && validateEmail(this.state.userEmail)){
            this.logout();
        }
        if (this.state.loading === true) {
            return (
                <Loading primaryColor={tenantVariables.primaryColor} backgroundImage={tenantVariables.backgroundImage}/>
            )
        }
        let renderMainScreen = "";
        if(this.state.gameActive && !this.state.locationChecking){
            renderMainScreen = this.renderPlayingScreen()
        } else {
            renderMainScreen = this.renderHoldingScreen()
        }
        let maxWidthAllowed = 300;
        const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
        if(bodyWidth > 0 && bodyWidth < 300){
            maxWidthAllowed = bodyWidth;
        }
        return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + tenantVariables.backgroundImage + ")"}}>
              {this.state.startConfetti &&
                <Confetti width={this.state.width} height={this.state.height} style={{zIndex: 1 }}/>
              }
              <div className="flex-content-container-home">
                  <div className="intro-container-home">
                      <div className="grid-wrapper">
                          <div className="flex-header-home" style={{marginTop:10, width: "100vw"}}>
                              <div style={{display: isMlbApp? "block":"none", textAlign: "left"}}>
                                  <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Support</button>
                              </div>
                              <div style={{visibility: rulesShowInApp? "visible":"hidden", marginLeft: isMlbApp && "auto"}}>
                                  <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                              </div>
                              <div style={{display: (isMlbApp || this.props.passedEmail || isBearsApp) && "none", marginRight:10}}>
                                  <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
                              </div>
                          </div>
                          {renderMainScreen}
                      </div>
                  </div>
              </div>
              <Modal isOpen={this.state.winnerModal} id="myOtherModal">
                  <center style={{maxWidth:maxWidthAllowed, marginTop:10, marginBottom: 10}}>
                      <span className="unselectable">Hold your finger on the box to save this coupon to your photos<br/>👇👇👇👇👇👇</span>
                      <div className="unselectable" style={{height:10, width: "100%"}}/>
                      <center id="downloadModalId"/>
                  </center>
              </Modal>
              <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesShowInAppPopUpHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
          </div>
        )
    }
}

export default Main_App;
